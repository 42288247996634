import React from "react"
import Service from "./Service"
import styles from "./service.module.scss"
const ServiceComparison = ({ services, meta }) => {
  return (
    <section className={styles.serviceGrid}>
      {services.value.map(({ elements, id }) => (
        <Service key={id} serviceInfo={elements} meta={meta} />
      ))}
    </section>
  )
}

export default ServiceComparison
