import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styles from "./navigation-tile.module.scss"
const NavigationTile = ({
  tiles,
  whereWeServiceSlug,
  whereWeServiceActive,
}) => {
  const { background_image, cta, heading, description, internal_link, icon } =
    tiles
  const { __typename } = internal_link.value[0]
  const { slug } = internal_link.value[0].elements
  let newSlug = slug.value
  if (
    __typename === "kontent_item_service_area" &&
    whereWeServiceActive === "true"
  ) {
    newSlug = `/${whereWeServiceSlug}/${slug.value}`
  }

  return (
    <Link to={newSlug} className={styles.navTileLink}>
      <div className={styles.navTileWrapper}>
        <Img
          fluid={background_image.value[0].fluid}
          className={styles.navTile}
          alt={background_image.value[0].description}
        />
        {icon.value.length > 0 && (
          <div className={styles.iconWrapper}>
            <img
              src={icon.value[0].url}
              alt="icon for navigation"
              className={styles.icon}
              loading="lazy"
            />
          </div>
        )}
      </div>
      <div className={styles.innerText}>
        <h2 className={styles.navTileHeading}>{heading.value}</h2>
        <p>{description.value}</p>
        <span className="primary-ghost-button">{cta.value}</span>
      </div>
    </Link>
  )
}

export default NavigationTile
