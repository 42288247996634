import React, { useState } from "react"
import Img from "gatsby-image"
import Slider from "react-slick"
import styles from "./gallery.module.scss"
const Gallery = ({ images }) => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }
  return (
    <div className={styles.gallery}>
      <Slider asNavFor={nav2} {...settings} ref={slider1 => setNav1(slider1)}>
        {images.value.map(({ elements, id }) => {
          const { single_image } = elements
          return (
            <div key={id} className={styles.imgContainer}>
              <Img
                fluid={single_image.value[0].fluid}
                className={styles.navImage}
                alt={single_image.value[0].description}
              />
            </div>
          )
        })}
      </Slider>
      <Slider
        asNavFor={nav1}
        slidesToShow={3}
        swipeToSlide={true}
        focusOnSelect={true}
        ref={slider2 => setNav2(slider2)}
      >
        {images.value.map(({ elements, id }) => {
          const { single_image } = elements
          return (
            <div key={id + 1} className={styles.imgContainer}>
              <Img
                fluid={single_image.value[0].fluid}
                className={styles.navImage}
                alt={single_image.value[0].description}
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default Gallery
