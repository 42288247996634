import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "./googleRating.module.scss"

const LandingGoogleRating = ({ url }) => {
  const data = useStaticQuery(getData)

  const { averageRating, isDevelop, noCompass } = data.average
  const { totalReviews } = data.count

  const officeName =
    data.allKontentItemSettings.nodes[0].elements.company_name.value

  if ((!isDevelop && noCompass) || !averageRating) return null

  return (
    <div
      className={styles.googleRatingWrapper}
      style={{ backgroundColor: "transparent", marginTop: 50 }}
    >
      {/* google logo here */}
      <span id="landing-google-reviews">
        <span className={styles.landingReviewWrapper}>
          <div className="no-margin" style={{ color: "#fff" }}>
            <strong>
              <span>{officeName}</span>
            </strong>
            {` received an average rating of `}
            <strong>
              <span>{averageRating.toFixed(1)}</span>{" "}
            </strong>
            out of{" "}
            <strong>
              <span>5</span> stars
            </strong>{" "}
            from{" "}
            <strong>
              <span>{totalReviews}</span> reviews.
            </strong>
          </div>
          <div className="no-margin flex center">
            <style
              dangerouslySetInnerHTML={{
                __html: `:root{ --rating: ${averageRating}}`,
              }}
            ></style>
            <div
              className={styles.landingStars}
              aria-label={`Rating of this product is ${averageRating.toFixed(
                1
              )} out of 5.`}
            >
              {averageRating.toFixed(1)}
            </div>
          </div>
        </span>
      </span>
    </div>
  )
}

const getData = graphql`
  query {
    average {
      averageRating
      isDevelop
      noCompass
    }
    count {
      totalReviews
    }
    allKontentItemSettings {
      nodes {
        elements {
          company_name {
            value
          }
        }
      }
    }
  }
`

export default LandingGoogleRating
