import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styles from "./image-link.module.scss"
const ImageLink = ({ imageLink }) => {
  const { image, internal_link, url } = imageLink

  const altText = image.value[0].description

  if (internal_link.length > 0) {
    return (
      <div className={styles.imageLink}>
        <Link to={`/${internal_link}`}>
          <Img fluid={image.value[0].fluid} alt={altText} />
        </Link>
      </div>
    )
  } else {
    return (
      <a  
        href={url.value}
        className={styles.imageLink}
        target="_blank"
        rel="noreferrer"
      >
        <Img fluid={image.value[0].fluid} alt={altText} />
      </a>
    )
  }
}

export default ImageLink
