import React, { useContext } from "react"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import Img from "gatsby-image"
import { AppContext } from "../context/context"
import styles from "./reviewBlock.module.scss"
const ReviewCard = ({ info, number }) => {
  const { review, rating, reviewer, location, review_image } = info
  const { label: locationName } = location.value[0].elements
  const { size } = useContext(AppContext)
  const getRating = rating => {
    switch (rating.value[0].codename) {
      case "n1_star":
        return "★"
      case "n2_stars":
        return "★★"
      case "n3_stars":
        return "★★★"
      case "n4_stars":
        return "★★★★"
      case "n5_stars":
        return "★★★★★"
      default:
        break
    }
  }

  return (
    <div className={styles.card}>
      {number % 2 !== 0 && size >= 1366 && (
        <Img
          fluid={review_image.value[0].fluid}
          alt=""
          className={styles.reviewImage}
        />
      )}
      <span className={styles.cardBody}>
        <RichTextElement value={review.value} />
        <div className={styles.stars}>{getRating(rating)}</div>
        <p className={styles.reviewer}>
          <strong className={styles.reviewName}>{reviewer.value}</strong>
          {locationName?.value.length > 0 && ` | ${locationName.value}`}
        </p>
      </span>
      {(number % 2 === 0 || size < 1366) && (
        <Img
          fluid={review_image.value[0].fluid}
          alt=""
          className={styles.reviewImage}
        />
      )}
    </div>
  )
}

export default ReviewCard
