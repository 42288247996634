import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styles from "./reviewBlock.module.scss"
const SocialLogos = ({ socials }) => {
  return socials.value.map(({ elements, id }) => {
    const { url, internal_link, image, single_image } = elements
    if (single_image) {
      const { fluid, description, width } = single_image.value[0]
      return (
        <Img
          key={id}
          fluid={fluid}
          alt={description}
          className={styles.socialLogo}
          style={{ width: width }}
        />
      )
    } else {
      const { width, fluid, description } = image.value[0]

      return internal_link.value.length > 0 ? (
        <Link to={internal_link.value}>
          <Img fluid={fluid} style={{ width: width }} alt={description} />
        </Link>
      ) : (
        <a href={url.value} key={id}>
          <Img fluid={fluid} style={{ width: width }} alt={description} />
        </a>
      )
    }
  })
}

export default SocialLogos
