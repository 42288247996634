import React from "react"
import ImageLink from "../image-link/ImageLink"
import RepeatingImage from "../repeating-image/RepeatingImage"
import styles from "./affiliation-slider.module.scss"
const AffiliationSlider = ({ heading, images, content = false }) => {
  return (
    <div className={content === true ? styles.affiliationWhiteContainer : styles.affiliationGreyContainer}>
      <div className={styles.affiliationSlider} >
        {heading ? <h2>{heading}</h2> : ""}
        <div className={styles.imageContainer}>
          {images.value.map(({ id, elements }) => {
            if (!!elements.internal_link || !!elements.url) {
              return <ImageLink key={id} imageLink={elements} />
            } else {
              return <RepeatingImage key={id} images={elements} />
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default AffiliationSlider
