import { Field } from "formik"
import React from "react"
import { FieldContainer } from "./FieldContainer"
import styles from "./form-styles.module.scss"

const PLACEHOLDER_MESSAGE = {
  full_name: "Tony Smith",
  zip: "96000",
  phone: "(888) 888-8888",
}

export const FieldsParser = ({ fieldType, elements, arrayIndex }) => {
  const { form_field, label, required, hidden_field_value } = elements

  const formFieldCodename =
    form_field?.value.length > 0 ? form_field?.value[0]?.codename : ""

  const isCustomFormField = formFieldCodename === "custom"

  const fieldLabel = label?.value
  const customFieldLabel = fieldLabel?.replace(/\s+/g, "")

  const htmlFor = isCustomFormField ? customFieldLabel : formFieldCodename

  const isRequired =
    required?.value.length > 0 ? required?.value[0]?.codename : ""

  switch (fieldType) {
    case "date":
      return (
        <FieldContainer
          key={`${fieldType}-${htmlFor}-${arrayIndex}`}
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
          className={styles.field}
        >
          <Field name={htmlFor} type="date" />
        </FieldContainer>
      )

    case "email":
      return (
        <FieldContainer
          key={`${fieldType}-${htmlFor}-${arrayIndex}`}
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
          className={styles.field}
        >
          <Field
            id="emailField"
            name={htmlFor}
            type="email"
            placeholder="name@email.com"
          />
        </FieldContainer>
      )

    case "long_text":
      return (
        <FieldContainer
          key={`${fieldType}-${htmlFor}-${arrayIndex}`}
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
          className={`${styles.field} ${styles.textArea}`}
        >
          <Field name={htmlFor} as="textarea" placeholder="Message" />
        </FieldContainer>
      )

    case "select":
      const optsArr = elements?.options?.value?.split(",")
      return (
        <FieldContainer
          key={`${fieldType}-${htmlFor}-${arrayIndex}`}
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
          className={styles.field}
        >
          <Field name={htmlFor} as="select">
            {optsArr.map((option, index) => {
              return <option key={option + index}>{option}</option>
            })}
          </Field>
        </FieldContainer>
      )

    case "radio":
      const optionsArr = elements?.options?.value?.split(",")
      return (
        <FieldContainer
          key={`${fieldType}-${htmlFor}-${arrayIndex}`}
          role="group"
          aria-labelledby={`${htmlFor}-group`}
          className={styles.radioContainer}
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
        >
          <div className={styles.radioGroup}>
            {optionsArr.map((option, i) => {
              return (
                <label
                  key={`${fieldType}-${htmlFor}-${arrayIndex}${i}`}
                  htmlFor={htmlFor + i}
                  className={styles.radio}
                >
                  <Field type="radio" name={htmlFor} value={option} />
                  {option}
                </label>
              )
            })}
          </div>
        </FieldContainer>
      )

    case "hidden":
      return (
        <Field
          key={`${fieldType}-${htmlFor}-${arrayIndex}`}
          name={htmlFor}
          type="hidden"
          value={hidden_field_value?.value}
        />
      )
    default:
      return (
        <FieldContainer
          key={`${fieldType}-${htmlFor}-${arrayIndex}`}
          htmlFor={htmlFor}
          label={fieldLabel}
          isFieldRequired={isRequired}
          className={styles.field}
        >
          <Field
            name={htmlFor}
            type="text"
            placeholder={PLACEHOLDER_MESSAGE[htmlFor] ?? ""}
          />
        </FieldContainer>
      )
  }
}
