import { ErrorMessage } from "formik"
import React from "react"
import styles from "./form-styles.module.scss"

export const FieldContainer = ({
  children,
  htmlFor,
  label,
  isFieldRequired,
  role,
  ...rest
}) => {
  return (
    <div {...rest}>
      <label htmlFor={role && role === "group" ? `${htmlFor}-group` : htmlFor}>
        {label}:{" "}
        <span className="required">{isFieldRequired === "yes" ? "*" : ""}</span>
      </label>
      {children}
      <span className={`required ${styles.formValidationErrorMessage}`}>
        <ErrorMessage name={htmlFor} />
      </span>
    </div>
  )
}
