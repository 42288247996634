import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import AffiliationSlider from "../affiliation-slider/AffiliationSlider"
import FormBuilder from "../forms/FormBuilder"
import ParentFAQ from "../faq/ParentFAQ"
import Gallery from "../image-gallery/Gallery"
import MultiContentBlock from "../multi-content-block/MultiContentBlock"
import NavigationTile from "../navigation-tile/NavigationTile"
import ServiceComparison from "../service-comparison/ServiceComparison"
import BasicContentBlock from "./BasicContentBlock"
import styles from "./content-block.module.scss"
import BlogPostV2 from "../blog/BlogPostV2"
import ReviewBlock from "../review-block/ReviewBlock"
import { AppContext } from "../../components/context/context"

const ContentBlock = ({
  blocks,
  pageTypes,
  meta,
  enableBuyOnline,
  whereWeServiceActive,
  position,
  blockCount,
  isPost,
  isLandingPage = false,
}) => {
  const {
    heading,
    sub_heading,
    content,
    image,
    content_block,
    navigation_tiles,
    logo_slider_heading,
    logo_slider,
    single_image,
    multi_block_heading,
    locations,
    location_heading,
    form,
    form_heading,
    form_sub_heading,
    gallery,
    image_gallery,
    services,
    alt_background_color,
    html,
    buttons,
    faq_list,
    first_content_section,
    main_content_section,
    featured_image,
    reviews,
    background_image,
    review_images,
    social_logos,
  } = blocks
  const { size } = React.useContext(AppContext)
  const { whereWeService, mainOffice } = useStaticQuery(getCustomSlug)

  const phone =
    mainOffice.nodes[0].elements.corporate_head_office.value[0].elements
      .tracking_number.value
  const whereWeServiceSlug = whereWeService.edges[0].node.elements.slug.value
  //jeff bridges astride a space unicorn.

  const getNavTiles = () => {
    if (navigation_tiles) {
      // Navigation tiles are a specific type of content block that is returned by Content blocks.
      return (
        <div className="container nav-tile-wrapper">
          {blocks.navigation_tiles.value.map(({ id, elements }) => {
            return (
              <NavigationTile
                key={id}
                tiles={elements}
                pageTypes={pageTypes}
                whereWeServiceSlug={whereWeServiceSlug}
                whereWeServiceActive={whereWeServiceActive}
              />
            )
          })}
        </div>
      )
    }
  }
  const getFAQs = () => {
    if (faq_list) {
      return (
        <section className="container">
          <ParentFAQ
            heading={heading}
            sub_heading={sub_heading}
            faq_list={faq_list}
            pageTypes={pageTypes}
          />
        </section>
      )
    }
  }

  const getMultiContentBlockClassNameByBlockCount = blockCount => {
    switch (blockCount) {
      case 2:
        return styles.multiContentBlockWrapper2Blocks
      case 4:
      case 8:
        return styles.multiContentBlockWrapper4Blocks
      case 5:
        return styles.multiContentBlockWrapper5Blocks
      case 7:
        return styles.multiContentBlockWrapper7Blocks
      default:
        return styles.multiContentBlockWrapper3Blocks
    }
  }

  const getMultiContentBlock = () => {
    if (content_block) {
      // A multi content block is made up of 2 or more content blocks. So here we map over the content blocks to return each block.
      const blockCount = content_block.value.length
      const multiContentBlockClassName =
        getMultiContentBlockClassNameByBlockCount(blockCount)

      return (
        <article className={`container ${styles.multiContentBlock}`}>
          {multi_block_heading.value !== "" && (
            <h2>{multi_block_heading.value}</h2>
          )}
          {sub_heading.value !== "" && <h3>{sub_heading.value}</h3>}
          <div className={multiContentBlockClassName}>
            {blocks.content_block.value.map(({ id, elements }) => {
              return (
                <MultiContentBlock
                  key={id}
                  blocks={elements}
                  pageTypes={pageTypes}
                />
              )
            })}
          </div>
        </article>
      )
    }
  }
  const getImage = () => {
    if (single_image && !isLandingPage) {
      return (
        <div className={`container ${styles.singleImage}`}>
          <Img
            fluid={single_image.value[0].fluid}
            alt={single_image.value[0].description}
          />
        </div>
      )
    }
  }

  const getLandingImage = () => {
    if (single_image && isLandingPage && size > 780) {
      return (
        <div className={`container ${styles.landingImage}`}>
          <Img
            fluid={single_image.value[0].fluid}
            alt={single_image.value[0].description}
          />
        </div>
      )
    }
  }

  const getLocation = () => {
    if (locations) {
      return (
        <article className={`container ${styles.locationsList}`}>
          <h3>{location_heading.value}</h3>
          <RichTextElement
            value={locations.value}
            links={locations.links}
            resolveLink={(link, domNode) => {
              return (
                <Link to={`/${whereWeServiceSlug}/${link.url_slug}`}>
                  {domNode.children[0].data}
                </Link>
              )
            }}
          />
        </article>
      )
    }
  }
  const getServiceComparison = () => {
    if (services) {
      return (
        <article className="container">
          <ServiceComparison services={services} meta={meta} />
        </article>
      )
    }
  }
  const getGallery = () => {
    if (gallery) {
      return (
        <section className="container">
          <Gallery images={gallery} />
        </section>
      )
    }
  }
  const getFormBanner = () => {
    if (form && isLandingPage === false) {
      const isContactUsForm = meta?.slug?.includes("contact")
      return (
        <section className="container">
          <FormBuilder
            heading={form_heading}
            subHeading={form_sub_heading}
            form={form}
            formGridStyle={`form-grid-2`}
            phone={phone}
            isSubHeadingLeftAligned={isContactUsForm}
          />
        </section>
      )
    }
  }

  const getLandingFormBanner = () => {
    if (form && isLandingPage === true) {
      console.log("landingform")
      return (
        <FormBuilder
          heading={form_heading}
          subHeading={form_sub_heading}
          formGridStyle={`landing-form-grid`}
          form={form}
          isLandingPage={isLandingPage}
          phone={phone}
        />
      )
    }
  }

  const getReviewBlock = () => {
    if (reviews) {
      return (
        <ReviewBlock
          reviews={reviews}
          review_images={review_images}
          background={background_image}
          heading={heading}
          subHeading={sub_heading}
          socials={social_logos}
        />
      )
    }
  }

  const getAffiliationBanner = () => {
    if (logo_slider_heading) {
      return (
        <section className="container">
          <AffiliationSlider
            content={true}
            heading={logo_slider_heading.value}
            images={logo_slider}
          />
        </section>
      )
    }
  }

  const getV2BlogContent = () => {
    if (first_content_section) {
      return (
        <section className="container">
          <BlogPostV2
            main_content_section={main_content_section}
            first_content_section={first_content_section}
            featured_image={featured_image}
            pageTypes={pageTypes}
          />
        </section>
      )
    }
  }

  const getContentBlock = () => {
    if (content) {
      return (
        <section className="container">
          <BasicContentBlock
            content={content}
            heading={heading}
            subHeading={sub_heading}
            html={html}
            image={image}
            image_gallery={image_gallery}
            pageTypes={pageTypes}
            buttons={buttons}
            isMulti={false}
            meta={meta}
            alt_background_color={alt_background_color}
            whereWeServiceSlug={whereWeServiceSlug}
            isPost={isPost}
            position={position}
            blockCount={blockCount}
          />
        </section>
      )
    }
  }

  return (
    <>
      {getNavTiles()}
      {getFAQs()}
      {getFormBanner()}
      {getGallery()}
      {getAffiliationBanner()}
      {getImage()}
      {getLocation()}
      {getServiceComparison()}
      {getMultiContentBlock()}
      {getContentBlock()}
      {getV2BlogContent()}
      {getLandingImage()}
      {getReviewBlock()}
      {getLandingFormBanner()}
    </>
  )
}

const getCustomSlug = graphql`
  query slugs {
    mainOffice: allKontentItemSettings {
      nodes {
        elements {
          corporate_head_office {
            value {
              ... on kontent_item_office {
                id
                elements {
                  tracking_number {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    whereWeService: allKontentItemPage(
      filter: {
        elements: {
          page_type: {
            value: { elemMatch: { name: { eq: "Where We Service" } } }
          }
        }
      }
    ) {
      edges {
        node {
          elements {
            slug {
              value
            }
          }
        }
      }
    }
  }
`

export default ContentBlock
